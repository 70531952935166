body {
  font-family: Arial, sans-serif;
  text-align: center;
}

.app {
  margin-top: 20px;
}

h1 {
  font-size: 2.5em;
  color: #333;
}

.select-container,
.range-container {
  margin-bottom: 15px;
}

input[type="text"],
input[type="number"] {
  width: 100px;
  padding: 5px;
  margin: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.result {
  margin: 10px 0;
  font-weight: bold;
}

.score {
  margin-top: 20px;
  font-size: 1.2em;
}

.ads-container {
  margin-top: 20px;
  text-align: center;
}